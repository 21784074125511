import React from 'react'

// Utils
import { getFont } from '../../../utils/utils'

// Styles
import {
  teamPb,
  teamImg,
  teamName,
  teamCard,
  teamTitle,
  teamRight,
} from './styles.module.scss'

interface Props {
  img: string
  name: string
  titles: string
}

const TeamCard: React.FC<Props> = ({ img, name, titles }) => {
  const arrTitles = titles ? titles.split(',') : []
  return (
    <div className={teamCard}>
      <img
        className={teamImg}
        src={`https://dilancovak.com/.img/about/${img}.png`}
      />
      <div className={teamRight}>
        <h3 className={`${teamName} ${getFont('font-patua', teamPb)}`}>
          {name}
        </h3>
        {arrTitles.map((tit) => (
          <p
            key={`${name}_title_${tit}`}
            className={`${teamTitle} ${getFont('font-lato')}`}
          >
            {tit}
          </p>
        ))}
      </div>
    </div>
  )
}

export default TeamCard
