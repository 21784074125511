// extracted by mini-css-extract-plugin
export var aboutBottom = "styles-module--about-bottom--iGUEm";
export var aboutContainer = "styles-module--about-container--8NEC+";
export var aboutDescription = "styles-module--about-description--cGpQ+";
export var aboutEmail = "styles-module--about-email--7emkS";
export var aboutInfo = "styles-module--about-info--DI6Pc";
export var aboutMiddle = "styles-module--about-middle--dMDup";
export var aboutTeam = "styles-module--about-team--Ebszz";
export var aboutTitle = "styles-module--about-title--36vIu";
export var aboutTop = "styles-module--about-top--HRFmT";
export var aboutWrapper = "styles-module--about-wrapper--ydqSs";
export var aboutYellowBorder = "styles-module--about-yellow-border--S9eLx";
export var aboutYellowSquare = "styles-module--about-yellow-square--H-PSu";