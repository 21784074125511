import React from 'react'

// Utils
import { getFont } from '../../../utils/utils'

// Localization
import { about } from '../../../config/localization'

// Componenets
import TeamCard from '../../molecules/TeamCard'

// Style
import {
  aboutTop,
  aboutTeam,
  aboutInfo,
  aboutTitle,
  aboutEmail,
  aboutMiddle,
  aboutBottom,
  aboutWrapper,
  aboutContainer,
  aboutDescription,
  aboutYellowBorder,
  aboutYellowSquare,
} from './styles.module.scss'

interface Props {
  language: string
}

const AboutUsPanel: React.FC<Props> = ({ language }) => (
  <div className={aboutWrapper}>
    <div className={`container ${aboutContainer}`}>
      <div className={aboutTop}>
        <div className={aboutYellowSquare}>
          <div className={`${aboutTitle} ${getFont('font-patua')}`}>
            {about[language].title}
          </div>
          <div className={aboutYellowBorder} />
        </div>
        <div className={`${aboutDescription} ${getFont('font-lato')}`}>
          {about[language].description}
        </div>
      </div>
      <h3 className={`${aboutTeam} ${getFont('font-patua')}`}>
        {about[language].team}
      </h3>
      <div className={aboutMiddle}>
        <TeamCard
          img={'Dani'}
          name={'Daniel Penagos'}
          titles={about[language].daniel}
        />
        <TeamCard
          img={'Amayi'}
          name={'Sebastian Amaya'}
          titles={about[language].sebastian}
        />
        <TeamCard
          img={'Osquitar'}
          name={'Oscar Torres'}
          titles={about[language].oscar}
        />
      </div>
      <div className={aboutBottom}>
        <div className={`${getFont('font-patua')} ${aboutInfo}`}>
          {about[language].info}
        </div>
        <div className={`${getFont('font-lato')} ${aboutEmail}`}>
          info@dilancovak.com
        </div>
      </div>
    </div>
  </div>
)

export default AboutUsPanel
