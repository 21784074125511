import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

// Components
import PageTemplate from '../../components/templates/PageTemplate'
import AboutUsPanel from '../../components/organisms/AboutUsPanel'

// Context
import LocalizationContext from '../../context/Localization/Localization.context'

const LanguagePage = () => {
  const { language } = useContext(LocalizationContext)
  return (
    <PageTemplate>
      <Helmet>
        <title>
          {language === 'ESP' ? 'Nosotros ' : 'About Us '}- Dilan
          {language === 'ESP'
            ? ' las crónicas de Covak'
            : ' The chronicles of Covak'}
        </title>
        <meta
          name="description"
          content={
            language === 'ESP'
              ? 'Aprende sobre Dilan: las crónicas de Covak, el equipo y nuestra historia.'
              : 'Learn about Dilan: the Chronicles of Covak, the team and our history.'
          }
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? 'Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, mundo, trama, nosotros'
              : 'Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, world, bios, story, about us'
          }
        />
        <meta name="author" content="Daniel Penagos" />
        <meta name="copyright" content="Daniel Penagos" />
      </Helmet>
      <AboutUsPanel language={language} />
    </PageTemplate>
  )
}

export default LanguagePage
